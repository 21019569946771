/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
export const APP_PREFIX = '/wifundity/';
export const FULLPAGE_PREFIX = '/wifundity/';
export const ORDER = `${APP_PREFIX}order-management`;


const PATH: { [path: string]: string } = {
    LOGIN: '/wifundity/',
    ORDER_MGMT: ORDER,
    ORDER_DETAIL: `${ORDER}/:id`,
    SCRIPT_MGMT: `${APP_PREFIX}script-management`,
};

export const PATH_MAP = Object.keys(PATH).reduce<{ [key: string]: string }>(
    (map, key) => ({ ...map, [PATH[key]]: key }),
    {}
);

export default PATH;
