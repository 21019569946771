import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material';
import { StyleSheetManager } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { getMessage } from 'assets/i18n';
import Router from 'router/router';
import './index.sass';
import './public-path';
import actions from 'actions';
import AppContextProvider, { GlobalContext } from 'store/appStore';
import reportWebVitals from './reportWebVitals';

const packageName = require('../package.json').name;

const theme = createTheme({
    components: {
        MuiSlider: {
            styleOverrides: {
                rail: {
                    color: '#9A9A9A',
                    height: '1px',
                },
                track: {
                    color: '#414141',
                },
                thumb: {
                    color: '#028BBA',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: '33px',
                    fontSize: '16px',
                    padding: '1.5px 12px',
                    backgroundColor: '#006E94',
                    color: 'white',
                    border: '1px solid transparent',
                    '&:hover': {
                        background: '#028BBA',
                    },
                    '&.Mui-disabled': {
                        color: 'white',
                        opacity: 0.5,
                    },
                },
                outlined: {
                    backgroundColor: 'white',
                    color: '#006E94',
                    borderColor: '#006E94',
                    '&:not(.tertiary)&:hover': {
                        background: '#028BBA',
                        color: 'white',
                        border: '1px solid transparent',
                        svg: {
                            '*': {
                                stroke: 'white',
                            },
                        },
                    },
                    '&:not(.tertiary)&.Mui-disabled': {
                        color: '#006E94',
                        opacity: 0.3,
                        borderColor: '#006E94',
                    },
                    '&.tertiary': {
                        backgroundColor: 'white',
                        color: '#454545',
                        borderColor: '#E3E3E3',
                        '&:hover': {
                            background: '#F2F2F2',
                            borderColor: '#E3E3E3',
                        },
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: '#F5F5F5',
                        '&.Mui-focusVisible': {
                            background: '#F5F5F5',
                        },
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#006E94',
                    '&.Mui-checked': {
                        color: '#006E94',
                    },
                    '&.MuiCheckbox-indeterminate': {
                        color: '#006E94',
                    },
                },
            },
        },
    },
});

const AppWrapper: React.FC = () => {
    const { lang } = useContext(GlobalContext);
    useEffect(() => {
        console.log('lang', lang);
    }, [lang]);

    return (
        <IntlProvider
            locale={lang}
            messages={getMessage(lang)}
            defaultLocale="tw"
        >
            <Router />
        </IntlProvider>
    );
};

let root: any = null;
// root.render(
//     // <React.StrictMode>
//     //     <LocalizationProvider dateAdapter={AdapterDayjs}>
//     //         <AppContextProvider>
//     //             <ThemeProvider theme={theme}>
//     //                 <AppWrapper />
//     //             </ThemeProvider>
//     //         </AppContextProvider>
//     //     </LocalizationProvider>
//     // </React.StrictMode>
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <AppContextProvider>
//             <ThemeProvider theme={theme}>
//                 <AppWrapper />
//             </ThemeProvider>
//         </AppContextProvider>
//     </LocalizationProvider>
// );

const RouteMain = () => (
    <StyleSheetManager disableCSSOMInjection>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppContextProvider>
                <ThemeProvider theme={theme}>
                    <AppWrapper />
                </ThemeProvider>
            </AppContextProvider>
        </LocalizationProvider>
    </StyleSheetManager>
);

let rootDOM: any = null;
function render(props: any) {
    const { container } = props;
    if (container) {
        // if (rootDOM == null) {
        //     rootDOM = ReactDOM.createRoot(container.querySelector('#root'));
        // }
        // rootDOM.render(<RouteMain />);
        rootDOM = ReactDOM.createRoot(container.querySelector('#root'));
        rootDOM.render(<RouteMain />);
    } else {
        root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
        root.render(<RouteMain />);
    }
}

function storeTest(props: any) {
    if (props) {
        // 注入 actions 实例
        actions.setActions(props);
    }
    // props.onGlobalStateChange(
    //     (value: any, prev: any) => console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev),
    //     true,
    // );
    // props.setGlobalState({
    //     ignore: packageName,
    //     user: {
    //         name: props.name,
    //     },
    // });
    console.log('[mount]', {
        ignore: packageName,
        user: {
            name: props.name,
        },
    });
}

/* eslint no-underscore-dangle: ["error", { "allow": ["__POWERED_BY_QIANKUN__"] }] */
if (!(window as any).__POWERED_BY_QIANKUN__) {
    render({});
}

export async function bootstrap() {
    console.log('[wifundity] react app bootstraped');
}

export async function mount(props: any) {
    console.log('[wifundity] props from main framework', props);
    storeTest(props);
    render(props);
}

export async function unmount(props: any) {
    const { container } = props;
    if (container) {
        // ReactDOM.unmountComponentAtNode(container.querySelector('#root'));
        rootDOM.unmount();
    } else {
        root.unmount();
    }
}

// if (process.env.NODE_ENV === 'development') {
    window.qiankunLifecycle = {
        bootstrap,
        mount,
        unmount,
    };
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
