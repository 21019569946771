import React, { lazy, Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
// import OrderMgmtNew from 'containers/OrderMgmt/OrderMgmt';
// import OrderDetailNew from 'containers/OrderDetail/OrderDetail';
// import ScriptMgmtNew from 'containers/ScriptMgmt/ScriptMgmt';
import PATH, { APP_PREFIX, FULLPAGE_PREFIX } from './pathConstants';
import Authentication from './Authentication';

export const WaitingComponent = (Component: any) =>
    function (props: any) {
        return (
            <Suspense fallback={<div>Module loading....</div>}>
                <Component {...props} />
            </Suspense>
        );
    };

const EmptyPage = () => (
    <>Opps... Something went wrong. Please check your url path again.</>
);

/*
  Layout
*/
const App = WaitingComponent(lazy(() => import('containers/App/App/App')));
const FullPage = WaitingComponent(
    lazy(() => import('containers/App/FullPage/FullPage'))
);

const routes = () => (
    <Route element={<Authentication />}>
        <Route path={FULLPAGE_PREFIX} element={<FullPage />}>
            {FullpageRoutes()}
        </Route>
        <Route path={APP_PREFIX} element={<App />}>
            {AppRoutes()}
        </Route>
        {/* <Route path={APP_PREFIX} element={<App />}>
            <Route path='/order-management' element={<OrderMgmtNew/>} />
            <Route path='/order-management/:id' element={<OrderDetailNew/>} />
            <Route path='/script-management' element={<ScriptMgmtNew/>} />
        </Route> */}
    </Route>
);

/*
  APP Routes
*/
const Order = WaitingComponent(lazy(() => import('containers/App/App/Order')));
const OrderMgmt = WaitingComponent(
    lazy(() => import('containers/OrderMgmt/OrderMgmt'))
);
const OrderDetail = WaitingComponent(
    lazy(() => import('containers/OrderDetail/OrderDetail'))
);
const ScriptMgmt = WaitingComponent(
    lazy(() => import('containers/ScriptMgmt/ScriptMgmt'))
);
const orderRoutes: RouteProps[] = [
    { path: PATH.ORDER_MGMT, element: <OrderMgmt /> },
    { path: PATH.ORDER_DETAIL, element: <OrderDetail /> },
];
const scriptRoutes: RouteProps[] = [
    { path: PATH.SCRIPT_MGMT, element: <ScriptMgmt /> },
];
const appRoutes = [...scriptRoutes, ...orderRoutes];

export const AppRoutes = () => (
    <Route path={PATH.ORDER} element={<Order />}>
        {appRoutes.map((config) => (
            <Route key={`route_${config.path}`} {...config} />
        ))}
    </Route>
);

/*
  FULLPAGE Routes
*/
const Login = WaitingComponent(lazy(() => import('App')));

const fullpageRoutes: RouteProps[] = [
    { path: PATH.LOGIN, element: <Login /> },
    { element: <EmptyPage /> },
];

export const FullpageRoutes = () => (
    <>
        {fullpageRoutes.map((config) => (
            <Route key={`route_${config.path}`} {...config} />
        ))}
    </>
);

export default routes;
