import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PATH from './pathConstants';
// import actions from '../actions';

const Authentication: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isAuthenticated = !!sessionStorage.getItem('token');

    // if (isAuthenticated && location.pathname === PATH.LOGIN) {
    //     navigate(`${PATH.ORDER_MGMT}${location.search}`);
    // }
    // if (!isAuthenticated) {
    //     navigate(PATH.LOGIN);
    // }
    
    // useEffect(() => {
    //     // 注册观察者函数
    //     // onGlobalStateChange 第二个参数为true,表示立即执行一次观察者函数
    //     actions.onGlobalStateChange((state: any) => {
    //         if (state.user.page) {
    //             // history.push({ pathname: state.user.page});
    //             const x = PATH.LOGIN;
    //             navigate(state.user.page);
    //         }
    //         console.log('[state]', state);
    //     }, true);
    // }, [navigate]);
    return <Outlet />;
};

export default Authentication;
