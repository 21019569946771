import React, { useState, useMemo, useEffect } from 'react';
import { detectBrowserLanguage } from 'assets/i18n';
import useModal from 'hooks/useModal';

const browserlang = detectBrowserLanguage();

type MessageProps = {
    event: 'SUCCESS' | 'ERROR' | React.ReactNode;
    content: React.ReactNode;
};

interface GlobalContextType {
    lang: Language;
    setLang: (s: Language) => any;
    userInfo?: UserInfoProps;
    setUserInfo: (s: UserInfoProps) => any;
    isLoading: boolean;
    setIsLoading: (s: boolean) => any;
    customPath: Array<CustomPath>;
    setCustomPath: (s: Array<CustomPath>) => any;
    modal: ReturnType<typeof useModal<MessageProps>>;
    success: (msg: string) => void;
    error: (msg: string) => void;
}

export const GlobalContext = React.createContext<GlobalContextType>(
    {} as GlobalContextType
);

type AppcontextProps = {
    children?: React.ReactNode;
};

const AppContextProvider: React.FC<AppcontextProps> = ({ children }) => {
    const localLanguage = localStorage.getItem('language');
    const [lang, setLang] = useState<Language>(browserlang);
    const [userInfo, setUserInfo] = useState<UserInfoProps>();
    const [isLoading, setIsLoading] = useState(false);
    const [customPath, setCustomPath] = useState<Array<CustomPath>>([]);
    const modal = useModal<MessageProps>({ event: '', content: '' });

    useEffect(() => {
        if (localLanguage) setLang(localLanguage as Language);
    }, [localLanguage]);

    const success = (msg: string) =>
        modal.openModal({ event: 'SUCCESS', content: msg });

    const error = (msg: string) =>
        modal.openModal({ event: 'ERROR', content: msg });

    const value = useMemo(
        () => ({
            lang,
            setLang,
            userInfo,
            setUserInfo,
            isLoading,
            setIsLoading,
            customPath,
            setCustomPath,
            modal,
            success,
            error,
        }),
        [
            lang,
            setLang,
            userInfo,
            setUserInfo,
            isLoading,
            setIsLoading,
            customPath,
            setCustomPath,
            modal,
            success,
            error,
        ]
    );

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};

export default AppContextProvider;
